import React, { useEffect, useState } from "react"
import { FaSearch, FaTimes } from "react-icons/fa"
import ContentCard from "../../../components/content/contentCard.js"
import ContentSkeleton from "../../../components/ContentSkeleton.js"
import {
  getOneBlock,
  getCategoryInfo,
  getAllSections,
  getCategoryContents,
} from "../../../api/content.js"
import BackButton from "../../../components/buttons/BackButton.js"
import ContentPageLayout from "../../../components/ContentPageLayout.js"
import config, { firebaseInit } from "../../../config.js"
import firebase from "firebase"
import { Helmet } from "react-helmet"
import Seo from "../../../components/seo.js"

const Category = ({ params }) => {
  const [contentLoading, setContentLoading] = useState(true)
  const [filteredCards, setFilteredCards] = useState([])
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [noResults, setNoResults] = useState(false)
  const [categoryInfo, setCategoryInfo] = useState(null)
  const [imageHeight, setImageHeight] = useState("auto")
  const [userHasSuscription, setUserHasSuscription] = useState(null)
  const [section, setSection] = useState(null)
  const [categoryName, setCategoryName] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [blockTitle, setBlockTitle] = useState(null)

  const profileUri = config.serverUri
  const blockId = params.block
  const categoryId = params.id

  // Obtención de la información de la categoría y, en caso de no recibir bloque, de todos los bloques
  useEffect(() => {
    setIsLoading(true)
    getCategoryInfo(categoryId)
      .then(data => {
        console.log(data)
        setCategoryInfo(data)
        setCategoryName(data.title)
        setIsLoading(false)
      })
      .catch(error => {
        console.error("Error al obtener los datos de la categoría:", error)
        setIsLoading(false)
      })

    if (!blockId) {
      getCategoryContents(categoryId)
        .then(data => {
          if (data.blocks && Array.isArray(data.blocks)) {
            // Combinar las tarjetas de todos los bloques
            const allCards = data.blocks.reduce((cards, block) => {
              return cards.concat(block.cards || [])
            }, [])
            setFilteredCards(allCards)
            setNoResults(allCards.length === 0)
          }
          setIsLoading(false)
        })
        .catch(error => {
          console.error(
            "Error al obtener los contenidos de la categoría:",
            error
          )
          setIsLoading(false)
        })
    }
  }, [categoryId, blockId])

  useEffect(() => {
    setContentLoading(true)
    getAllSections().then(data => {
      setSection(data)
      setContentLoading(false)
    })
  }, [])

  const toggleSearch = () => {
    setSearchVisible(!searchVisible)
    setSearchText("")
    setNoResults(false)
    fetchCards() // Restaura las tarjetas originales
  }

  useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetch(`${profileUri}/users/?email=${user.email}`)
          .then(response => {
            if (response.ok) {
              response.json().then(data => {
                if (data[0] && data[0].suscription)
                  setUserHasSuscription(data[0].suscription)
              })
            }
          })
          .catch(error => console.log(error))
      } else {
        console.log("user is null")
        setUserHasSuscription(false)
      }
    })
  }, [])

  const fetchCards = async () => {
    try {
      setContentLoading(true)
      let url = ""
      if (blockId) {
        url = `${config.serverUri}/categories/contents/${categoryId}?block=${blockId}`
      } else {
        url = `${config.serverUri}/categories/contents/${categoryId}`
      }
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`Error fetching cards: ${response.statusText}`)
      }
      const data = await response.json()
      if (blockId) {
        // Cuando se envía el parámetro block, data.blocks es un objeto que incluye "cards" y "titulo"
        if (data.blocks) {
          setFilteredCards(data.blocks.cards ? data.blocks.cards : [])
          setBlockTitle(data.blocks.titulo ? data.blocks.titulo : "")
        } else {
          setFilteredCards([])
          setBlockTitle("")
        }
      } else {
        // Sin parámetro block, data.blocks es un array de bloques; se combinan todas las tarjetas
        if (data.blocks && Array.isArray(data.blocks)) {
          const allCards = data.blocks.reduce((cards, block) => {
            return cards.concat(block.cards || [])
          }, [])
          setFilteredCards(allCards)
        } else {
          setFilteredCards([])
        }
        // Al combinar todos los bloques, no se tiene un título específico
        setBlockTitle("")
      }

      // Actualizar la información de la categoría (opcional)
      const category = await getCategoryInfo(categoryId)
      console.log(category)
      setCategoryInfo(category)
      setNoResults(filteredCards.length === 0)
    } catch (error) {
      console.error("Error fetching cards:", error)
      setFilteredCards([])
      setNoResults(true)
    } finally {
      setContentLoading(false)
    }
  }

  // Si blockId cambia, se vuelve a buscar
  useEffect(() => {
    fetchCards()
  }, [blockId])

  useEffect(() => {
    const updateImageHeight = () => {
      if (!categoryInfo) {
        return
      }
      if (categoryInfo.desktopImage) {
        const aspectRatio =
          categoryInfo.desktopImage.height / categoryInfo.desktopImage.width
        setImageHeight(window.innerWidth * aspectRatio)
      }
    }
    console.log(categoryInfo?.desktopImage)
    updateImageHeight()
    window.addEventListener("resize", updateImageHeight)
    return () => {
      window.removeEventListener("resize", updateImageHeight)
    }
  }, [categoryInfo])

  const handleSearch = () => {
    if (!searchText.trim()) {
      fetchCards() // Restaura las tarjetas originales si no hay texto de búsqueda
      return
    }
    const filtered = filteredCards.filter(card =>
      card.titulo.toLowerCase().includes(searchText.toLowerCase())
    )
    setFilteredCards(filtered)
    setNoResults(filtered.length === 0)
  }

  const suscription = text => {
    text === "premium" || text === "free"
      ? setUserHasSuscription(text)
      : setUserHasSuscription(false)
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  return (
    <ContentPageLayout suscription={suscription} section={section}>
      <Helmet>
        <script>
          {`!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};ttq.load('CH4N0RRC77UEFTNV1RUG');ttq.page();}(window, document, 'ttq');`}
        </script>
      </Helmet>
      <Seo title={`Category - ${categoryName}`} />

      {contentLoading ? (
        <ContentSkeleton />
      ) : (
        <div>
          {/* Banner */}
          {categoryInfo && !contentLoading ? (
            <header>
              <div>
                <div className="flex p-4">
                  <div className="flex justify-center">
                    <BackButton
                      fontSize={30}
                      route={`/Category/${categoryId}`}
                    />
                    {blockId && blockTitle && (
                      <span className="text-left font-bold text-xl ml-4 ">
                        {blockTitle}
                      </span>
                    )}
                  </div>
                  {/* Botón de búsqueda */}
                  <div className="ml-auto flex items-center justify-end">
                    {!searchVisible ? (
                      <FaSearch
                        fontSize={18}
                        className="cursor-pointer"
                        onClick={() => setSearchVisible(true)}
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          onKeyDown={handleKeyDown}
                          placeholder="Buscar..."
                          className="bg-transparent border-b focus:outline-none pr-4"
                        />
                        <FaTimes
                          fontSize={18}
                          className="cursor-pointer ml-2"
                          onClick={toggleSearch}
                        />
                        <FaSearch
                          fontSize={18}
                          className="cursor-pointer ml-2"
                          onClick={handleSearch}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="relative bg-white"
                  style={{ width: "100%", height: `${imageHeight}px` }}
                >
                  <img
                    className="absolute"
                    src={
                      categoryInfo.desktopImage.url.trim().startsWith("http")
                        ? categoryInfo.desktopImage.url.trim()
                        : config.serverUri +
                          categoryInfo.desktopImage.url.trim()
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                    alt={categoryInfo.title}
                  />
                  <div
                    className="absolute inset-0"
                    style={{
                      backgroundColor: categoryInfo.color,
                      opacity: 0.6,
                      borderRadius: "20px",
                    }}
                  ></div>
                  <div className="absolute inset-0 flex justify-center items-center">
                    <h1 className="text-white text-center text-4xl font-bold">
                      {categoryInfo.title}
                    </h1>
                  </div>
                </div>
              </div>
            </header>
          ) : null}
          {/* Renderizado de las tarjetas */}
          <div className="flex flex-wrap justify-center my-8">
            {filteredCards.length > 0 ? (
              filteredCards.map((card, key) => (
                <div className="sm:full p-2" key={key}>
                  <ContentCard
                    card={card}
                    userHasSuscription={userHasSuscription}
                  />
                </div>
              ))
            ) : (
              <p>
                {noResults
                  ? "No hay elementos que coincidan con la búsqueda."
                  : "No hay tarjetas disponibles."}
              </p>
            )}
          </div>
        </div>
      )}
    </ContentPageLayout>
  )
}

export default Category
